import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootswatch/dist/lumen/bootstrap.css'

import './../css/site.sass'

const mainElement = document.querySelector("main")
const mainElementContent = mainElement.querySelector('div.container')

// Auto detect dark mode
if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
    document.querySelector("html").setAttribute("data-bs-theme", "dark")
} else {
    document.querySelector("html").setAttribute("data-bs-theme", "light")
}

// Underline current page in navbar
const navLinks = document.querySelectorAll('.navbar-nav a')
for (const element of navLinks) {
    if (element.getAttribute('href') === window.location.pathname) {
        element.parentNode.classList.add('active')
    } else {
        element.parentNode.classList.remove('active')
    }
}

// Check if page is scrollable
document.addEventListener("DOMContentLoaded", moveWavesIfTextToBig)
window.addEventListener("resize", moveWavesIfTextToBig)

function moveWavesIfTextToBig() {
    if (mainElementContent.offsetHeight > window.innerHeight-240) {
        mainElement.classList.add("scrollable")
    }
}